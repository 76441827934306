// $font-primary: 'neue-haas-grotesk-display', sans-serif;
// $font-primary: 'EverettMono-Regular', sans-serif;
$font-light: 'Barlow-Light', sans-serif;
$font-primary: 'Barlow-Regular', sans-serif;
$font-medium: 'Barlow-Medium', sans-serif;
$font-semi-bold: 'Barlow-SemiBold', sans-serif;
$font-bold: 'Barlow-Bold', sans-serif;
$bg-main: #0f0f0f;
$bp-light: white;
$bg-safari-main: #EAE9EA;
$text-color:  #FFFFFF;;
$text-color-inverted: #0f0f0f;
$text-color-dark: #000000;
$color-blue: #009ACE;
$color-purple: #8374DC;
$color-turquoise: #6CDCC4;
$color-coral: #FF7584;

// Primary Colors
$primary-header: #FFF6F0; // Used mostly for Headers and ‘selected page’ on navigation
$primary-body: #FFF6F0;   // Used for body text and unelected pages

// Secondary Colors
$secondary-hover: #FFCBA5;   // Used for hover state
$secondary-header: #C37f6E;   // Used for unselected pages and header and footer.
$secondary-icon: #000000;    // Used for icon lines

// Gradient Colors
// $gradient-homepage: linear-gradient(233deg, #1DB954 -7.29%, #30F 108.46%); // Background color for homepage
// $gradient-otherpages: linear-gradient(180deg, #533CA1 0%, rgba(0, 0, 0, 0.00) 63.75%), #000; // Background color for rest of the pages
// $gradient-complete: linear-gradient(270deg, #96361E, #5C1301); // Background color for all pages

$desktop-font-size: 22px;
$tablet-font-size: 17px;
$mobile-font-size: 16px;
$mobile-heading-font-size: 28px;

$desktop-font-size-high: 24px;


$desktop-line-height: 22px;
$desktop-line-height-high: 35px;
$tablet-line-height: 20px;
$mobile-line-height: 19px;

$desktop-menu-font-size: 15px;

/* Font */
/* desktop */
$letter-spacing: 2px;
$font-weight: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-h1: $font-weight-bold 20px/30px $font-semi-bold;
$font-h2: $font-weight-bold 18px/27px $font-semi-bold ;
$font-h3: $font-weight 20px/26px $font-light ;

$font-ques: $font-weight 18px/27px $font-light;
$font-body: $font-weight 15px/24px $font-light;

$font-nav: $font-weight-bold 16px/39px $font-semi-bold;
$font-info: $font-weight 14px/22px $font-light;
$font-small: $font-weight 10px/10px $font-primary;

/* mobile */
$font-h1-mobile: $font-weight-bold 18px/27px $font-semi-bold ;
$font-h2-mobile: $font-weight-bold 16px/24px $font-semi-bold;
$font-h3-mobile: $font-weight 18px/21.6px $font-light;

$font-ques-mobile: $font-weight 16px/24px $font-light;
$font-body-mobile: $font-weight 14px/24px $font-light;

$font-nav-mobile: $font-weight-bold 14px/39px $font-semi-bold;
$font-small-mobile: $font-weight 8px/8px $font-primary;


/*
    Breakpoints
 */
$xs: 375;
$small: 433;
$phone: 600;
$tablet: 902;
$tablet-portrait-down: 1024;
$desktop: 1248;
$laptop: 1366;
$widescreen: 1600;

$screen-xs-small: screen and
    (
        max-width: #{$xs}px,
    );
$screen-small: screen and
    (
        max-width: #{$small}px,
    );
$screen-mobile: screen and
    (
        max-width: #{$phone}px,
    );
$screen-tablet: screen and
    (
        max-width: #{$tablet}px,
    );
$tablet-portrait-and-down: screen and
    (
        max-width: #{$tablet-portrait-down}px
    );
$screen-desktop: screen and
    (
        min-width: #{$laptop}px,
    );
$desktop-and-down:
    (
        max-width: #{$desktop}px
    );
$laptop-and-down:
    (
        max-width: #{$laptop}px
    );
$widescreen-and-down:
    (
        max-width: #{$widescreen}px
    );
$screen-wide: screen and
    (
        min-width: #{$widescreen}px
    );
$screen-low-height: screen and
    (
        max-height: 768px
    );

$screen-mid-height: screen and
    (
        max-height: 860px
    );