@import "./../../scss/variables";

.logos {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 446px;
  height: 25px;

  @media #{$screen-mobile} {
    gap: 14.04px;
    width: 325px;
    height: 16.295px;
    align-self: center;
    justify-content: center;
  }
  // a {
  //   @media #{$screen-mobile} {
  //     // transform: scale(0.66);
  //   }
  // }
  // @media #{$screen-desktop} and (max-height: 768px) {
  //   margin-bottom: 0;
  // }

  .fl-logo {
    display: flex;
    width: 18.709px;
    height: 22.111px;

    @media #{$screen-mobile} {
      width: 13.788px;
      height: 16.295px;
    }
  }

  .akqa-logo {
    display: flex;
    width: 71px;
    height: 31px;

    @media #{$screen-mobile} {
      width: 52px;
      height: 23px;
    }
    // @media #{$screen-tablet} and (orientation: landscape) {
    //   height: 21px;
    //   padding-top: 2px;
    // }
  }

  .spotify-logo {
    display: flex;
    width: 82px;
    height: 25px;

    @media #{$screen-mobile} {
      width: 53px;
      height: 16px;
    }
    // @media #{$screen-tablet} and (orientation: landscape) {
    //   height: 21px;
    //   padding-top: 2px;
    // }
  }

  .cannes-logo {
    display: flex;
    width: 76px;
    height: 31px;

    @media #{$screen-mobile} {
      width: 56px;
      height: 23px;
    }
    // @media #{$screen-tablet} and (orientation: landscape) {
    //   height: 66px;
    //   width: 64px;
    //   margin-top: 23px;
    // }
  }

  .wsj-logo {
    display: flex;
    width: 197px;
    height: 18px;

    @media #{$screen-mobile} {
      width: 128px;
      height: 12px;
    }
    // @media #{$screen-tablet} and (orientation: landscape) {
    //   height: 21px;
    //   padding-top: 2px;
    // }
  }

  /*
  .volvo-logo {
    display: block;
    height: 20px;
    width: 130px;
    margin: 0 30px;

    @media #{$screen-mobile} {
      margin: 0px 20px;
      height: 16px;
    }
    @media #{$screen-tablet} and (orientation: landscape) {
      margin: 0px 20px;
      height: 16px;
    }
  }
  .forbes-logo {
    display: block;
    height: 34px;
    width: 60px;
    padding-top: 9px;
    margin: 0 30px;
    
    @media #{$screen-mobile} {
      height: 24px;
      width: 72px;
      padding-top: 4px;
      margin: 0 20px;
    }
    @media #{$screen-tablet} and (orientation: landscape) {
      height: 24px;
      width: 72px;
      padding-top: 4px;
      margin: 0 20px;
    }
  }
  */

}

.footer-logos{
  width: 794px;
  gap: 15px;
  justify-content: flex-start;

  .spotify-logo {
    width: 61px;
    height: 19px;
  }
  .wsj-logo {
    width: 146px;
    height: 14px;
  }
  .akqa-logo{
    width: 52px;
    height: 23px;
  }

  .cannes-logo{
    width: 56px;
    height: 23px;
  }
}
.countdown-logos{
  width: 130px;
  gap: 18px;
  justify-content: flex-start;
  .akqa-logo{
    width: 55px;
    height: 24px;
  }

  .cannes-logo{
    width: 58px;
    height: 24px;
  }
}

// .terms-and-conditions {

//   a {
//       text-transform: none;
//       text-decoration: none;
//       letter-spacing: 0.06px;
//       color: $primary-body;
//       line-height: 27px;
//       font-weight: 300;
//       font-size: 10px;
//       margin-right: 14px;

//       &:last-child
//       {
//           margin-right: 0;
//       }

//       @media #{$tablet-portrait-and-down}
//       {
//           display: block;
//       }
//   }

//   @media #{$tablet-portrait-and-down} {
//     display: flex;
//     justify-content: center;
//     position: unset;

//   }
// }

// .logos-light a {
//   color: white;
// }

