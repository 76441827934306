@import "variables";

$animationSpeed: 1500ms;


.brief {
    .lion-logo {
        cursor: pointer;
        z-index: 900;

            position: absolute;
            top: 31px;
            right: 75px;

            @media #{$tablet-portrait-and-down} {
                display: block;
            }
            @media #{$screen-mobile} {
                right: 25px;
                top: 23px;
            }
            @media #{$screen-tablet} and (orientation: landscape) {
                right: 25px;
                top: 23px;
            }

    .lion-text {
        font-size: 21px;
        font-family: 'Volvo-Broad-CN-Regular', sans-serif;
        color: black;
        @media #{$screen-mobile} {
            font-size: 17.7712px;
        }
        @media #{$screen-tablet} and (orientation: landscape) {
            font-size: 17.7712px;
        }

    }
    .lion-background {
        background-image: url('../../assets/2023/home/join-us-circle.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        position: absolute;
        width: 68.32px;
        height: 39.07px;
        left: -19px;
        top: -2px;

        @media #{$screen-mobile} {
            width: 51px;
            height: 26px;
            left: -14px;
            top: 5px;
        }
        @media #{$screen-tablet} and (orientation: landscape) {
            width: 51px;
            height: 26px;
            left: -14px;
            top: 5px;
        }
    }
    .logo-icon {
        margin-left: 34px;
        width: 22px;
        height: auto;
        top: -1px;

        @media #{$screen-mobile} {
            width: 18px;
            margin-left: 12px;
        }
        @media #{$screen-tablet} and (orientation: landscape) {
            width: 18px;
            margin-left: 12px;
        }
    }
    a {
        display: flex;
        align-items: center;
        &:link,&:visited
        {
            text-decoration: none;
        }
        &:hover,&:active
        {
        color: $text-color;
        }
    }
}
    .desktop-header-wrapper{
        position: fixed;
        left: 63px;
        top: 60px;
        width: 115px;
        height: 44px;
        z-index: 600;
        display: flex;
        flex-direction: row;
        // justify-content: space-between;
        align-items: center;
        font: $font-info;
        color: $secondary-header;
        text-align: left;
        // border: 1px solid white;

        @media #{$screen-mobile} {
            left: 20px;
            // width: 90%;
            // top: 100px;
        }

        .fl-title{
            color: $primary-header;
            text-align: left;
            width: 100%;
            // width: calc(25% - 25px);
            @media #{$screen-mobile} {
                width: 100%;
            }
        }
        .header-text-1{
            width: 329px;
            margin-right: 20px;
            @media #{$screen-mobile} {
                display: none;
            }
        }
        .header-text-2{
            // flex: 1 0;
            width: 50%;
            @media #{$screen-mobile} {
                display: none;
            }
        }
    }
    .fade-out{
        opacity: 1;
        transition: opacity 0.3s ease-out;
    }
    .fade-out.hidden {
        opacity: 0;
    }
    .header-bar-wrapper{
        display: flex;
        position: fixed;
        top: 60px;
        z-index: 999;
        width: 100%;
        justify-content: center;
        align-items: center;

        .header-bar{
            width: 794px;
            display: flex;
            flex-direction: row ;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 20px;
            font: $font-info;
            color: $secondary-header;

            @media #{$screen-mobile} {
                display: none;
            }
            .header-text-1{
                width: 329px;
                text-align: left;
            }
            .header-text-2{
                flex-grow: 1;
                text-align: left;
            }
        }
    }
    .down-arrow-wrapper {
        display: flex;
        position: absolute;
        bottom: 32px;
        z-index: 500;
        width: 100%;
        justify-content: center;

        @keyframes scaleAndFade {
            0%, 100% { 
                transform: scale(0.95); 
                opacity: 0.3; 
            }  
            50% { 
                transform: scale(1); 
                opacity: 1; 
            }  
        }

        .down-arrow {
            background: url(/assets/svg/arrow_down.svg) no-repeat center center;
            display: block;
            width: 48px;
            height: 48px;
            opacity: 0.3;
            animation: scaleAndFade 2s infinite ease-in-out;
        }
        @media (min-width: #{$phone}px) {
            display: none;
        }
    }
    .desktop-logos-wrapper {
        position: fixed;
        bottom: 40px;
        // left: 25%;
        width: 100%;
        // left: 50%;
        // transform: translate(-50%,0);
        z-index: 500;
        // height: 129px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        // display: flex;
        // flex-direction: row;
        // gap: 40px;
        // font: $font-info;
        // color: $secondary-header;
        @media screen and (max-width: 1190px) {
            justify-content: flex-start;
            margin-left: 20px;
            bottom: 50px;
        }
        // @media #{$screen-mid-height} {
        //     bottom: 45px;
        // }
        // @media #{$desktop-and-down} {
        //     justify-content: center;
        // }
        // @media (min-width: #{$phone}px) and (max-width: #{$tablet}px) {
        //     display: none;
        // }
        .footer-text{
            display: flex;
            flex-direction: row;
            gap: 40px;
            width: 794px;
            font: $font-info;
            
            a{
                text-decoration: none;
                color: $secondary-header;
            }

            @media #{$screen-tablet} {
                // left: 20px;
                display: grid;
                grid-template-columns: 30% 70%;
                width: 279px;
                gap: 10px;
                text-align: left;
                bottom: 70px;
                font: $font-nav-mobile;
            }
            @media #{$screen-mid-height} {
                // gap: 0px;
            }
        }

        // @media #{$desktop-and-down}  and (orientation: portrait) {
        //     transform: translate(-53%, -25%) scale(0.8);
        //     width: 100%;
        //     padding-left: 11%;
        // }

        // @media #{$screen-mobile} and (orientation: portrait) {
        //     transform: translate(-50%, 10%) scale(0.7);
        // }

        @media screen and ( max-height: 700px ) {
            // height: 50px;
        }
        
        // @media #{$screen-mobile} and ( max-height: 700px ) {
        //     transform: translate(-50%, 10%) scale(0.7);
        // }
        // @media #{$screen-tablet} and (orientation: landscape) {
        //     transform: translate(-50%, 10%) scale(0.7);
        // }

        // @media #{$screen-wide} {
        //     transform: translate(-50%,0%);
        // }
    }
    .terms-and-conditions {
        position: fixed;
        bottom: 40px;
        left: 63px;
        width: 150px;
        z-index: 600;
        display: flex;
        flex-direction: column;
        gap: 12px;
        align-items: flex-start;
        
        color: $primary-header;

        a {
            text-transform: uppercase;
            text-decoration: none;
            color: $primary-body;
            font: $font-small;
            // margin-right: 14px;
            
            &:hover
            {
                transition: 400ms;
                color: $secondary-hover;
                // opacity: 1;
            }
            &:last-child
            {
                margin-right: 0;
            }
      
            @media #{$tablet-portrait-and-down}
            {
                display: block;
                font: $font-small-mobile;
            }
        }
        
        @media screen and (max-width:1190px) {
            flex-direction: row;
            justify-content: flex-start;
            width: 100%;
            left: 20px;
            bottom: 20px;
          //   position: unset;
          }

        // @media #{$tablet-portrait-and-down} {
        //   flex-direction: row;
        //   justify-content: flex-start;
        //   width: 100%;
        //   left: 20px;
        //   bottom: 20px;
        // //   position: unset;
        // }
      }
    

    .mobile-logos-wrapper {
        position: fixed;
        bottom: 0;
        width: 100%;
        // left: 50%;
        // transform: translate(-50%, -50%);

        margin: 20px 0 0 0;
        display: none;

        @media #{$tablet-portrait-and-down}
        {
            display: block;
            position: absolute;
            bottom: 20px;
            left: 50%;
            transform: translate(-50%, 0);
        }
    }

    .page-wrapper {
        position: absolute;
        width: 100%;
        height: 100%;
        visibility: hidden;
        pointer-events: none;
        will-change: opacity, transform;

        &.active {
            z-index: 1;
            visibility: visible;
            pointer-events: auto;

            .lion-mobile {
                opacity: 1;
            }

        }
        // &.home{
        //     background: $gradient-homepage;
        // }
        // &.other{
        //     background: $gradient-otherpages;
        // }
    }

    .page-wrapper.wrapper-dates {
        @media #{$tablet-portrait-and-down}
        {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .page
            {
                position: relative;
                height: auto;
            }

            .right-column
            {
                position: relative;
                width: 100%;
                padding-left: 20px;
                height: auto;
            }
        }
    }

    .page-full-width {
        flex-direction: column;
        justify-content: center;
        box-sizing: border-box;
        position: absolute;
        top: 0px;
        height: 100%;
        width: 100%;
        align-items: center;
        z-index: -1;
        display: flex;

        @media #{$tablet-portrait-and-down}
        {
            padding: 0;
            z-index: auto;
        }
        @media #{$screen-mobile} {
            // top: 100px;
        }
        // @media #{$screen-low-height} {
        //     top: 50px;
        // }

        &.top-align
        {
            @media #{$tablet-portrait-and-down}
            {
                justify-content: flex-start;
            }
        }
        .title {
            // letter-spacing: 0.18px;
            // font-size: 32px;
            // line-height: 64px;
            // margin: 0 0 18px 0;
            // transform: translateX(-2px);
            margin: 0;
            width: 677px;
            height: 61px;
            text-align: center;
            font-size: 48px;
            font-style: normal;
            font-weight: 700;
            line-height: 54px;
            // font-family: "DMSans-Regular";

            &.home {
                padding: 0 2rem;
            }

            &.title-alumni-faq
            {

                margin-top: 2rem;
                margin-bottom: 11px;

                @media #{$tablet-portrait-and-down}
                {
                    padding-left: 30px;
                }
            }
            @media #{$screen-tablet}{
                width: 356px;
                height: 115px;
            }
            @media #{$tablet-portrait-and-down}
            {
                // letter-spacing: 0.11px;
                // font-size: $desktop-font-size-high;
                // margin-bottom: 11px;
                // padding: 0 10px;

                &.no-wrap-mobile {
                    white-space: nowrap;
                    display: flex;
                }
            }
        }
    }

    .page-background {
        left: 0;
        transform: none;
    }

    .page {
        flex-direction: column;
        justify-content: center;
        padding: 0;
        box-sizing: border-box;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 50%;
        align-items: flex-end;
        z-index: -1;
        display: flex;

        @media #{$tablet-portrait-and-down}
        {
            width: 100%;
        }

        $animationTime: 1s;

        .lion-mobile {
            opacity: 0;
            transition: $animationTime;
        }

        &.bottom-align
        {
            @media #{$tablet-portrait-and-down}
            {
                justify-content: flex-end;
            }
        }
    }

    .bulletpoints-container {
        position: fixed;
        top: 173px;
        left: 30px;
        // transform: translateY(-50%);
        z-index: 700;

        @media #{$screen-tablet} {
            left: 11px;
        }
    }


    // HOME PAGE
    .page-main {
        display: flex;
        justify-content: center;
        align-items: center;

        .title {
            font-size: 48px;
            font-weight: 600;
        }
        .subtitle {
            font-size: $desktop-font-size;
            font-weight: 400;
            // letter-spacing: 1px;
            width: 343px;
            height: 36px;
            font-style: normal;
            font-weight: 500;
            line-height: 36px;
            margin: 0;
        }

        $wideHomeHeroRatio: 850/655; // 1.297709923664122
        $mobileHomeHeroRatio: 500/655; // 0.763358778625954

        .homeCountdownWrapper {
            img{
                width: auto !important;
            }

            .logos-wrapper{
                .countdown {
                    display: flex;
                    // gap: 60px;
                    text-align: center;
                    justify-content: space-between;
                    width: 554px;

                    @media #{$screen-mobile} {
                        width: 385px;
                    }

                    .separator{
                        span{
                            font-size: 83px;
                            line-height: 82px;
                            @media #{$screen-tablet} {
                                font-size: 57px;
                                line-height: 57px;
                            }
                        }
                    }
                    .countdown-item {
                        font-size: 24px;
                        font-weight: bold;
                        width: 92px;
                        
                        @media #{$screen-mobile} {
                            width: 64px;
                        }

                        div{
                            font-size: 83px;
                            line-height: 82px;

                            @media #{$screen-tablet} {
                                font-size: 57px;
                                line-height: 57px;
                            }
                        }
                        p{
                            font-size: 15px;
                            line-height: 37px;
                            font-family: 'Barlow-Bold';
                            letter-spacing: 0.07em;
                            margin: auto;
                            color: $secondary-header;

                            @media #{$screen-tablet} {
                                font-size: 10px;
                                line-height: 25px;
                            }
                        }
                    }
                }
                .pre-title-text {
                    font: $font-h2;
                    letter-spacing: 2px;
                }
            }

            .logos-countdown{
                width: 130px;
                height: 23px;
                position: fixed;
                right: 64px;
                bottom: 34px;
                @media #{$screen-mobile} {
                    bottom: 70px;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }
        
        .home-hero-wrapper {
            position: relative;
            // height: 250px; // width 1203 px
            // width: 100%;
            display: flex;
            flex-direction: column;
            gap: 77px;
            align-items: center;
            width: 909px;
            padding-top: 10px;
            // top: 219px;
            // border: 1px solid green;

            @media screen and (max-width: 430px) {
                // margin-bottom: 50px;
            }

            @media screen and (max-width: 660px) {
                width: 90%;
            }
            @media #{$screen-mobile} {
                // height: 65%;
                gap: 125px;
                // top: 119px;
                justify-content: space-between;
            }
            // @media #{$screen-mid-height} {
            //     gap: 160px;
            // }
            img{
                width: 100%;
            }
            // @media #{$screen-wide} {
            //     height: 850px;
            // }

            // @media #{$desktop-and-down} {
            //     height: 500px;
            // }

            @media #{$tablet-portrait-and-down} {
                width: 90%;
            }

            // @media #{$desktop-and-down} and (orientation: portrait) {
            //     height: 655px;
            // }

            // @media #{$screen-mobile} {
            //     height: 500px;
            // }

            // @media #{$screen-low-height} {
            //     height: 500px;
            //     //height: 70vh;
            // }

            .logos-wrapper{
                display: flex;
                flex-direction: column;
                gap: 131px;
                align-items: center;
                @media #{$screen-mobile} {
                    align-self: center;
                    gap: 168px;
                }
            }

            .brief-enter {
                // margin-top: 9px;
                // text-align: center;
                width: 322px;
                @media screen and (max-width:320px){
                    width: 200px;
                    height: 42px;
                }
            }
            .entries-closed {
                width: 123px;
                font: $font-nav;
                letter-spacing: 0.07em;
            }

            .enter-button {
                height: 50px;
                border: 1px solid $primary-header;
                display: flex;
                justify-content: center;
                align-items: center;
                text-decoration: none;
                border-radius: 4px;

                @media #{$screen-mobile} {
                    height: 64px;
                }
                

                &:hover
                {
                    transition: 400ms;
                    border: 1px solid $secondary-hover;
                    .enter-text {
                        transition: 400ms;
                        color: $secondary-hover;
                    }
                }

                .enter-text {
                    color: $primary-header;
                    text-align: center;
                    font: $font-nav;
                    letter-spacing: 0.07em;

                    @media screen and (max-width:320px){
                       font-size: 14px;
                    }
                    @media #{$screen-mobile} {
                        font: $font-nav-mobile;
                    }
                    @media #{$screen-tablet} and (orientation: landscape) {
                        // font-size: $mobile-font-size;
                    }
                }
            }


            .home-hero-animate {
                position: absolute;
                top: 0;
                transform: translateX(55%);
                right: 50%;
                width: 1203px;
                height: 655px;
                backface-visibility: hidden;
                perspective: 1000px;

                @media #{$screen-wide} {
                    width: 1561px;
                    height: 850px;
                }

                @media #{$desktop-and-down} {
                    width: 918px;
                    height: 500px;
                }

                @media #{$tablet-portrait-and-down} {
                    transform: translateX(50%);
                }

                //@media #{$screen-tablet} and (orientation: portrait) {
                @media #{$desktop-and-down} and (orientation: portrait) {
                    transform: initial;
                    right: initial;
                    width: 1203px;
                    height: 655px;
                }

                @media #{$screen-mobile} {
                    right: initial;
                    left: 0;
                    transform: initial;
                    width: 918px;
                    height: 500px;
                }

                @media #{$screen-low-height} {
                    width: 918px;
                    height: 500px;
                }
            }

            .home-hero-image {
                height: 100%;

                img {
                    height: 100%;
                }
            }

            .fountain-image {
                position: absolute;
                top: 69.16%;
                right: 65.17%;
                width: 216px;
                z-index: 2;

                img {
                    width: 100%;
                }

                @media #{$screen-wide} {
                    width: calc(#{$wideHomeHeroRatio} * 216px);
                }

                @media #{$desktop-and-down} {
                    width: calc(#{$mobileHomeHeroRatio} * 216px);
                }

                @media #{$desktop-and-down} and (orientation: portrait) {
                    width: 216px;
                }

                @media #{$screen-mobile} {
                    width: calc(#{$mobileHomeHeroRatio} * 216px);
                }

                @media #{$screen-low-height} {
                    width: calc(#{$mobileHomeHeroRatio} * 216px);
                }
            }

            .fountain-lottie {
                position: absolute;
                top: 64.12%;
                right: 70.15%;
                width: 118px;
                opacity: 0.7;

                @media #{$screen-wide} {
                    width: calc(#{$wideHomeHeroRatio} * 118px);
                }

                @media #{$desktop-and-down} {
                    width: calc(#{$mobileHomeHeroRatio} * 118px);
                }

                @media #{$desktop-and-down} and (orientation: portrait) {
                    width: 118px;
                }

                @media #{$screen-mobile} {
                    width: calc(#{$mobileHomeHeroRatio} * 118px);
                }
                @media #{$screen-low-height} {
                    width: calc(#{$mobileHomeHeroRatio} * 118px);
                }
            }

            .smoke-lottie {
                position: absolute;
                top: 34.5%;
                right: 46.3%;
                width: 105px;
                opacity: 0.8;

                @media #{$screen-wide} {
                    width: calc(#{$wideHomeHeroRatio} * 105px);
                }

                @media #{$desktop-and-down} {
                    width: calc(#{$mobileHomeHeroRatio} * 105px);
                }

                @media #{$desktop-and-down} and (orientation: portrait) {
                    width: 105px;
                }

                @media #{$screen-mobile} {
                    width: calc(#{$mobileHomeHeroRatio} * 105px);
                }

                @media #{$screen-low-height} {
                    width: calc(#{$mobileHomeHeroRatio} * 105px);
                }
            }

            .ski-lift-lottie {
                position: absolute;
                top: 8.24%;
                right: 15.13%;
                width: 179px;

                @media #{$screen-wide} {
                    width: calc(#{$wideHomeHeroRatio} * 179px);
                }

                @media #{$desktop-and-down} {
                    width: calc(#{$mobileHomeHeroRatio} * 179px);
                }

                @media #{$desktop-and-down} and (orientation: portrait) {
                    width: 179px;
                }

                @media #{$screen-mobile} {
                    width: calc(#{$mobileHomeHeroRatio} * 179px);
                }

                @media #{$screen-low-height} {
                    width: calc(#{$mobileHomeHeroRatio} * 179px);
                }
            }
        }

        .mobile-logos-wrapper {
            @media #{$tablet-portrait-and-down} {
                bottom: 60px;
            }
        }
    }
    /*
    .page-brief {
        display: flex;
        justify-content: center;
        width: 628px;
        height: 100%;

        @media #{$tablet-portrait-and-down} {
            padding: 0;
            width: auto;
        }
        @media #{$screen-mobile} {
            width: auto;
        }
        .brief-container {
            display: flex;
            flex-direction: column;
            width: 100%;

            p {
                text-align: center;
            }

            .content-wrapper {
                width: 628px;
                padding-top: 45px;
                text-align: left;
                font-size: $desktop-font-size;
                line-height: $desktop-line-height;
                display: flex;
                justify-content: center;
                flex-direction: column;

                @media #{$tablet-portrait-and-down} {
                    width: 100%;
                }

                @media #{$screen-mobile} {
                    padding-top: 28px;
                    font-size: $mobile-font-size;
                    line-height: $mobile-line-height;
                }

                @media #{$screen-tablet} and (orientation: landscape) {
                    padding-top: 0;
                    font-size: $mobile-font-size;
                    line-height: 16px;
                }

                p:last-child {
                    margin-bottom: 5px;
                }

                .nowrap {
                    white-space: nowrap;
                }
            }

            .brief-header {
                position: relative;
                text-align: center;

                .brief-header-text {
                    font-family: 'Volvo-Broad-CN-Regular', sans-serif;
                    font-size: 32px;
                    line-height: 1em;
                    margin-bottom: 6px;

                    @media #{$tablet-portrait-and-down} {
                        font-size: $mobile-heading-font-size;
                    }
                    @media #{$screen-tablet} and (orientation: landscape) {
                        font-size: $mobile-heading-font-size;
                    }
                }

                @media #{$tablet-portrait-and-down} {
                    font-size: $mobile-heading-font-size;
                    display: inline-block;
                }
            }

            .brief-description {
                width: 797px;
                position: relative;
                left: -13%;
                line-height: $desktop-line-height;

                @media #{$tablet-portrait-and-down} {
                    width: 100%;
                    position: initial;
                    padding: 0 15%;
                }

                @media #{$screen-mobile} {
                    padding: 0 37px;
                    line-height: $mobile-line-height;
                }

                @media #{$screen-tablet} and (orientation: landscape) {
                    padding: 0 37px;
                    line-height: 1;
                }
                p {
                    margin-bottom: 5px;
                    margin-top: 5px;
                }
            }

            .brief-cta {
                margin-top: 9px;
                text-align: center;
            }

            .cta-button {
                align-self: flex-start;
                min-width: 150px;
                text-decoration: none;
                color: $text-color;

                .cta-text {
                    font-size: $desktop-font-size;
                    line-height: $desktop-line-height;
                    font-family: 'Volvo-Novum-Medium', sans-serif;
                    color: #000000;

                    @media #{$screen-mobile} {
                        font-size: $mobile-font-size;
                    }
                    @media #{$screen-tablet} and (orientation: landscape) {
                        font-size: $mobile-font-size;
                    }
                }
            }

            .heroWrapper {
                margin: 18px 0 0;
                height: 512px;
                position: relative;
                text-align: center;

                @media #{$tablet-portrait-and-down} {
                    margin: 30px auto;
                    width: 418px;
                    height: 392px;
                }

                @media #{$screen-mobile} {
                    margin: 20px auto;
                    width: 380px;
                    height: 357px;
                }
                @media (max-height: 700px) and (orientation: portrait) {
                    margin: 17px auto;
                }
                @media #{$screen-tablet} and (orientation: landscape) {
                    margin: 0 auto;
                    height: 194px;
                }
            }

            .hero-image {
                width: 1400.33px;
                height: 512px;
                position: absolute;
                left: -61%;
                bottom: 0;
                top: initial;

                @media #{$tablet-portrait-and-down} {
                    width: 100%;
                    left: 0;
                    top: 0;
                    img {
                        object-fit: contain;
                        max-width: 418px;
                        max-height: 392px;
                    }
                }

                @media #{$screen-mobile} {
                    img {
                        max-width: 380px;
                        max-height: 357px;
                    }
                }
                @media #{$screen-tablet} and (orientation: landscape) {
                    height: 330px;
                    top: 0;
                }
            }

            .video-wrapper {
                width: 100%;
                margin: 0 !important;
                position: absolute;
                bottom: 218px;

                .brief-video {
                    width: auto;
                }

                @media #{$tablet-portrait-and-down} {
                    top: 36px;
                    left: -3px;
                    bottom: initial;
                }

                @media #{$screen-mobile} {
                    top: 24px;
                }
                @media #{$screen-tablet} and (orientation: landscape) {
                    top: 15px;
                }
            }

            .sound-wave-lottie-left {
                width: 50px;
                position: absolute;
                bottom: 82px;

                &-1 {
                    left: 125px;
                    transform: rotateZ(180deg);
                    transform-origin: center;
                }

                &-2 {
                    left: 77px;
                }

                @media #{$tablet-portrait-and-down} {
                    width: 40px;
                    bottom: initial;
                    top: 285px;
                    left: 60px;

                    &-2 {
                        left: 25px;
                    }
                }

                @media #{$screen-mobile} {
                    top: 260px;
                    left: 55px;
                    &-2 {
                        left: 20px;
                    }
                }
            }

            .sound-wave-lottie-right {
                width: 50px;
                position: absolute;
                bottom: 82px;

                &-1 {
                    right: 105px;
                }

                &-2 {
                    right: 58px;
                    transform: rotateZ(180deg);
                    transform-origin: center;
                }

                @media #{$tablet-portrait-and-down} {
                    width: 40px;
                    bottom: initial;
                    top: 285px;
                    right: 50px;//30px;
                    &-2 {
                        right: 16px;
                    }
                }

                @media #{$screen-mobile} {
                    top: 260px;
                    right: 45px;
                    &-2 {
                        right: 10px;
                    }
                }
            }

        }

    }
    */
    .page-winners,.page-awards{
        .brief-container {
            .winners-description,.awards-description{
                font: $font-body;
                text-align: left;
                width: 740px;
                letter-spacing: -1px;
                
                @media #{$screen-mobile} {
                    font: $font-body-mobile;
                }
                @media #{$screen-tablet} {
                    width: 100%
                }
                p{
                    margin: 0;
                    padding: 0;
                    color: $primary-body;
                    .nowrap {
                        white-space: nowrap;
                    }
                }
            }
        }
    }
    .page-brief {
        
        // @media #{$tablet-portrait-and-down} {
        //     padding: 0;
        //     width: auto;
        // }
        // @media #{$desktop-and-down} {
        //     width: 525px;
        // }
        // @media #{$screen-mobile} {
        //     width: auto;
        // }
        
        .brief-container {
            width: 678px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            // margin-left: 20px;
            gap: 78px;
            // border: 1px solid red;

            @media #{$screen-tablet} {
                width: 90%;
            }
            @media #{$desktop-and-down} {
                // width: 600px;
            }
            @media #{$tablet-portrait-and-down} {
                margin-left: 0px;
            }
            @media #{$screen-mobile} {
                width: 90%;
                gap: 100px;
            }
            // @media #{$screen-mid-height} {
            //     gap: 20px;
            // }

            p {
                text-align: center;
            }

            .content-wrapper {
                // width: 628px; //708px
                // padding-top: 45px;   
                // text-align: left;
                // font-size: $desktop-font-size;
                // line-height: $desktop-line-height;
                // display: none;
                // justify-content: space-between;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 10px;
                width: 100%;

                @media #{$tablet-portrait-and-down} {
                    // width: 100%;
                }
                @media #{$screen-mobile} {
                    display: flex;
                    width: auto;
                    gap: 32px;
                    flex-direction: column;
                }
                // @media #{$screen-mid-height} {
                //     gap: 20px;
                // }

                // @media #{$screen-mobile} {
                //     // padding-top: 28px;
                //     // font-size: $mobile-font-size;
                //     // line-height: $mobile-line-height;
                // }

                // @media #{$screen-tablet} and (orientation: landscape) {
                //     padding-top: 0;
                //     font-size: $mobile-font-size;
                //     line-height: 16px;
                // }

                // p:last-child {
                //     margin-bottom: 5px;
                // }

                .nowrap {
                    white-space: nowrap;
                }
            }

            .brief-header {
                // position: relative;
                // text-align: center;
                width: 329px;

                .brief-header-text {
                    // font-size: 32px;
                    // line-height: 1em;
                    // margin-bottom: 6px;
                    color: $primary-header;
                    // text-align: left;
                    font: $font-weight-bold 20px/30px $font-semi-bold;
                    letter-spacing: 2px;

                    @media #{$screen-mobile} {
                        font: $font-h1-mobile;
                    }
                    @media #{$screen-tablet} and (orientation: landscape) {
                        // font-size: $mobile-heading-font-size;
                    }
                }

                // @media #{$tablet-portrait-and-down} {
                    // font-size: $mobile-heading-font-size;
                    // display: inline-block;
                // }
            }

            .brief-description {
                // width: 797px;
                // position: relative;
                // left: -13%;
                // line-height: $desktop-line-height;
                // display: none;
                // width: 445px;

                @media #{$screen-mobile} {
                    width: 389px;
                    // padding: 0 37px;
                    // line-height: $mobile-line-height;
                }
                @media #{$screen-small} {
                    width: 100%;
                }

                @media #{$screen-tablet} and (orientation: landscape) {
                    // padding: 0 37px;
                    // line-height: 1;
                }
                p {
                    // margin-bottom: 5px;
                    // margin-top: 5px;
                    color: $primary-body;
                    font: $font-h3;
                    letter-spacing: 1px;
                    margin: 0;
                    @media #{$screen-mobile} {
                        font: $font-h3-mobile;
                    }
                }
            }

            .brief-cta {
                // margin-top: 9px;
                // text-align: center;
                width: 278px;
                height: 100%;
                @media #{$screen-mobile} {
                    // width: 324px;
                    align-self: center;
                }
                @media screen and (max-width:320px){
                    width: 200px;
                    height: 42px;
                }
            }

            .cta-button {
                // align-self: flex-start;
                // min-width: 150px;
                // text-decoration: none;
                // color: $text-color;
                height: 42px;
                // border-radius: 4px;
                border: 2px solid $secondary-header;
                display: flex;
                justify-content: center;
                align-items: center;
                text-decoration: none;

                @media #{$screen-mobile} {
                    // height: 64px;
                }

                &:hover
                {
                    transition: 400ms;
                    border: 1px solid $secondary-hover;
                    .cta-text {
                        transition: 400ms;
                        color: $secondary-hover;
                    }
                }

                .cta-text {
                    // font-size: $desktop-font-size;
                    // line-height: $desktop-line-height;
                    // font-family: 'Volvo-Novum-Medium', sans-serif;
                    // color: #000000;
                    color: $secondary-header;
                    text-align: center;
                    font: $font-nav;
                    letter-spacing: 0.07em;

                    @media screen and (max-width:320px){
                       font-size: 14px;
                    }
                    @media #{$screen-mobile} {
                        // font: $font-nav-mobile;
                    }
                    @media #{$screen-tablet} and (orientation: landscape) {
                        // font-size: $mobile-font-size;
                    }
                }
            }

            .heroWrapper {
                width: 100%;
            //     margin: 18px 0 0;
            //     height: 512px;
            //     position: relative;
            //     text-align: center;

            //     @media #{$tablet-portrait-and-down} {
            //         margin: 30px auto;
            //         width: 418px;
            //         height: 392px;
            //     }

            //     @media #{$screen-mobile} {
            //         margin: 20px auto;
            //         width: 380px;
            //         height: 357px;
            //     }
            //     @media (max-height: 700px) and (orientation: portrait) {
            //         margin: 17px auto;
            //     }
            //     @media #{$screen-tablet} and (orientation: landscape) {
            //         margin: 0 auto;
            //         height: 194px;
            //     }
            }

            // .hero-image {
            //     width: 1400.33px;
            //     height: 512px;
            //     position: absolute;
            //     left: -61%;
            //     bottom: 0;
            //     top: initial;

            //     @media #{$tablet-portrait-and-down} {
            //         width: 100%;
            //         left: 0;
            //         top: 0;
            //         img {
            //             object-fit: contain;
            //             max-width: 418px;
            //             max-height: 392px;
            //         }
            //     }

            //     @media #{$screen-mobile} {
            //         img {
            //             max-width: 380px;
            //             max-height: 357px;
            //         }
            //     }
            //     @media #{$screen-tablet} and (orientation: landscape) {
            //         height: 330px;
            //         top: 0;
            //     }
            // }

            .video-wrapper {
                // width: 100%;
                // margin: 0 !important;
                // position: absolute;
                // bottom: 218px;

                // .brief-video {
                //     width: auto;
                // }

                // @media #{$tablet-portrait-and-down} {
                //     top: 36px;
                //     left: -3px;
                //     bottom: initial;
                // }

                // @media #{$screen-mobile} {
                //     top: 24px;
                // }
                // @media #{$screen-tablet} and (orientation: landscape) {
                //     top: 15px;
                // }
            }

            // .sound-wave-lottie-left {
            //     width: 50px;
            //     position: absolute;
            //     bottom: 82px;

            //     &-1 {
            //         left: 125px;
            //         transform: rotateZ(180deg);
            //         transform-origin: center;
            //     }

            //     &-2 {
            //         left: 77px;
            //     }

            //     @media #{$tablet-portrait-and-down} {
            //         width: 40px;
            //         bottom: initial;
            //         top: 285px;
            //         left: 60px;

            //         &-2 {
            //             left: 25px;
            //         }
            //     }

            //     @media #{$screen-mobile} {
            //         top: 260px;
            //         left: 55px;
            //         &-2 {
            //             left: 20px;
            //         }
            //     }
            // }

            // .sound-wave-lottie-right {
            //     width: 50px;
            //     position: absolute;
            //     bottom: 82px;

            //     &-1 {
            //         right: 105px;
            //     }

            //     &-2 {
            //         right: 58px;
            //         transform: rotateZ(180deg);
            //         transform-origin: center;
            //     }

            //     @media #{$tablet-portrait-and-down} {
            //         width: 40px;
            //         bottom: initial;
            //         top: 285px;
            //         right: 50px;//30px;
            //         &-2 {
            //             right: 16px;
            //         }
            //     }

            //     @media #{$screen-mobile} {
            //         top: 260px;
            //         right: 45px;
            //         &-2 {
            //             right: 10px;
            //         }
            //     }
            // }

        }

    }

    .page-milestone {
        // width: 641px;

        @media #{$tablet-portrait-and-down} {
            // width: 600px;
        }
        @media #{$screen-mobile} {
            // width: 400px;
        }

        .milestone-container {
            display: flex;
            flex-direction: row;
            justify-content: center;
            // margin: auto;
            width: 794px;
            gap: 20px;
            // border: 1px solid white;
            // padding-top: 18px;
            
            @media #{$screen-mobile} {
                // padding-top: 30px;
                flex-direction: column;
                width: 90%;
                gap: 22px;
                align-items: center;
            }
            // @media #{$screen-mid-height} {
            //     gap: 20px;
            // }
            @media #{$screen-tablet} {
                // flex-direction: column;
                width: 90%;
                // gap: 20px;

                // .content-wrapper {
                //     width: 100%;
                //     padding-left: 0px;
                //     align-items: center;

                // }
            }
            // @media #{$laptop-and-down} {
            //     margin-left: 5%;
            // }
            // @media #{$desktop-and-down} {
            //     margin-left: 10%;
            // }

            .description-wrapper {
                font-size: 18px;
                display: none;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;
                width: 329px;
                text-align: left;
                // padding-bottom: 30px;
                
                @media #{$tablet-portrait-and-down} {
                    // padding: 8% 10%;
                    
                    padding-top: 0;
                 }
                @media #{$screen-tablet} and (orientation: landscape) {
                    padding: 0;
                }
                @media #{$screen-mobile} {
                    display: flex;
                    text-align: center;
                }
                .header {
                    position: relative;
                    display: inline-block;
                    // margin-bottom: 6px;
                    // display: none;
                    width: 100%;

                    .header-text {
                        font: $font-h1;
                        @media #{$screen-mobile} {
                            font: $font-weight-bold 18px/27px $font-semi-bold;
                            letter-spacing: 2px;
                        }
                    }

                    @media #{$tablet-portrait-and-down} {
                        display: inline-block;
                        // margin-bottom: 23px;
                        // height: 69px;
                    }
                    @media #{$screen-tablet} and (orientation: landscape) {
                        // margin-bottom: 20px;
                        // height: 69px;
                    }
                }

                .description-text {
                    font-size: $desktop-font-size;
                    line-height: $desktop-line-height;
                    text-align: center;
                    max-width: 791px;
                    position: relative;
                    left: -6%;


                    p {
                        margin: 0;
                    }
                    @media #{$tablet-portrait-and-down} {
                        position: initial;
                    }
                    @media #{$screen-mobile} {
                        font-size: $mobile-font-size;
                        line-height: $mobile-line-height;
                    }

                    @media #{$screen-tablet} and (orientation: landscape) {
                        font-size: $mobile-font-size;
                        line-height: 16px;
                        position: initial;
                        width: 100%;
                    }
                }
                // @media #{$screen-tablet} {
                //     width: 100%;
                // }
            }

            
            .heroWrapper {
                // height: 574px;
                // position: relative;
                text-align: left;
                width: 445px;
                // left: -257px;
                @media #{$tablet-portrait-and-down} {
                    // margin: 0;
                    // width: 100%;
                    // left: 0;
                }
                // @media #{$tablet-portrait-and-down} {
                //     height: 485px;
                // }
                // @media #{$screen-tablet} {
                //     width: 100%;
                // }
                .iconsWrapper{
                    display: flex;
                    width: 100%;
                    color: red;
                    // justify-content: center;
                    @media #{$screen-mobile} {
                        justify-content: flex-start;
                        // margin-left: 30px;
                    }
                }
            }

            .hero-image {
                width: 482px;
                height: 381.55px;
                position: absolute;
                left: 337px;
                bottom: 182px;
                
                @media #{$tablet-portrait-and-down} {
                    width: 100%;
                    left: 2px;
                    height: 303.55px;
                    img {
                        object-fit: cover;
                        max-width: 374px;
                        max-height: 296px;

                        @media #{$screen-tablet} and (orientation: landscape) {
                            object-fit: scale-down;
                            max-width: 343px;
                        }
                    }
                }
                @media #{$screen-tablet} and (orientation: landscape) {
                    left: 6px;
                    top: 3px;
                }
                
            }
            .frame-rat {
                width: 476px;
                position: relative;
                top: -18px;         

                @media #{$tablet-portrait-and-down} {
                    top: -16px;
                    width: 372px;
                }

                @media #{$screen-mobile} {
                    top: -15px;
                    width: 372px;
                }
            }
            .hero-image-two {
                width: 1090px;
                height: 187px;
                position: absolute;
                left: 38px;
                bottom: 16px;

                @media #{$tablet-portrait-and-down} {
                    width: 100%;
                    left: 0;

                    img {
                        object-fit: cover;
                    }
                }
                @media #{$screen-tablet} and (orientation: landscape) {
                    display: none;
                }
            }

        }
    }

    .page-centered {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .page-school-of-year {
        font-size: $desktop-font-size-high;
        line-height: $desktop-line-height-high;
        .school-of-year-container {
            width: 814px;
            margin-left: 20px;
            // border: 1px solid green;
            @media #{$screen-mobile} {
                width: 90%;
            }
            @media #{$tablet-portrait-and-down} {
                width: 90%;
                margin-left: 0px;
            }
        }
        .header {
            // display: none;
            // width: 329px;
            @media #{$screen-small} {
                width: 100%;
            }
            .header-text {
                font: $font-weight-bold 20px/30px $font-semi-bold;
                letter-spacing: 2px;
                // text-align: left;
                @media #{$screen-mobile} {
                    font: $font-weight-bold 18px/27px $font-semi-bold;
                }
            }

            @media #{$tablet-portrait-and-down} {
                // display: inline-block;
                // margin-bottom: 23px;
                // height: 69px;
            }
        }

        .description-wrapper {
            // width: 50%;
            // max-width: 837px;
            // margin: auto;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            gap: 10px;
            @media #{$screen-mobile} {
                flex-direction: column;
                gap: 20px;
            }
            // @media #{$screen-small} {
            //     gap: 0px;
            // }
            @media #{$tablet-portrait-and-down} {
                width: 100%;
                // gap: 20px;
            }
            
        }
        .no-wrap,.nowrap {
            white-space: nowrap;
        }
        .description-text {
            width: 562px;
            // font-weight: 300;
            // overflow-y: auto;
            // overflow-x: hidden;
            display: flex;
            flex-direction: column;
            gap: 20px;
            @media #{$screen-mobile} {
                width: 389px;
            } 
            @media #{$screen-small} {
                width: 100%;
            }
            p {
                color: $primary-body;
                font: $font-ques;
                letter-spacing: 1px;
                @media #{$screen-mobile} {
                    font: $font-ques-mobile;
                }
            }
            .description-title {
                color: $primary-header;
                text-align: center;
                margin: 0;
                font: $font-h2;
                @media #{$screen-mobile} {
                    font: $font-h2-mobile;
                }
            }
            .description-content {
                display: flex;
                flex-direction: column;
                gap: 10px;
                text-align: center;
                @media #{$screen-mobile} {
                    gap: 14px;
                }
                p {
                    margin: 0;
                }
            }
        }
    }
    .page-alumni {
        // justify-content: flex-start;
        // padding-left: 6%;
        
        .alumni-container{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 947px;
            margin-left: 153px;
            // height: 100%;
            // border: 1px solid green;

            @media #{$desktop-and-down} {
               margin-left: 0px;
            }
            @media #{$laptop-and-down} {
                width: 835px;
            }
            @media #{$tablet-portrait-and-down} {
                width: 90%;
            }
  
        }
        @media screen and (max-width: 1450px) {
            // padding-left: 12%;
            max-width: 80%;
        }
        // @media #{$laptop-and-down} {
        //     // padding-left: 10%;
        //     padding-left: 18%;
        //     max-width: 80%
        // }
        @media #{$desktop-and-down} {
            // padding-left: 18%;
            max-width: 80%;
        }
        @media #{$tablet-portrait-and-down} {
            // padding: 5%;
            // width: 769px;
            max-width: 100%;
        }
        @media #{$screen-mobile} {
            padding: 5%;
        }
        @media #{$screen-tablet} and (orientation: landscape) {
            margin: auto;
        }
    }

    .page-cubhouse {
        // justify-content: flex-start;
        // padding-left: 6%;
        
        .cubhouse-container{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 947px;
            margin-left: 153px;
            // height: 100%;
            // border: 1px solid green;

            @media #{$desktop-and-down} {
               margin-left: 0px;
            }
            @media #{$laptop-and-down} {
                width: 835px;
            }
            @media #{$tablet-portrait-and-down} {
                width: 90%;
            }
  
        }
        @media screen and (max-width: 1450px) {
            // padding-left: 12%;
            max-width: 80%;
        }
        // @media #{$laptop-and-down} {
        //     // padding-left: 10%;
        //     padding-left: 18%;
        //     max-width: 80%
        // }
        @media #{$desktop-and-down} {
            // padding-left: 18%;
            max-width: 80%;
        }
        @media #{$tablet-portrait-and-down} {
            // padding: 5%;
            // width: 769px;
            max-width: 100%;
        }
        @media #{$screen-mobile} {
            padding: 5%;
        }
        @media #{$screen-tablet} and (orientation: landscape) {
            margin: auto;
        }
    }

    .page-faq {
        // width: 928px;
        // justify-content: flex-start;
        .faq-container {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 794px;
            padding-bottom: 75px;
            // margin-bottom: 10%;
            // border: 1px solid white;

            // @media #{$laptop-and-down} {
            //     width: 700px;
            // }
            // @media #{$desktop-and-down} {
            //     width: 540px;
            // }
            @media #{$screen-tablet} {
                width: 90%;
                
                // margin-bottom: 0;
            }
            @media #{$screen-mobile} {
                padding-bottom: 50px;
            }
        }
        @media #{$tablet-portrait-and-down} {
            // padding: 10%;
            // top: 0;
            // width: auto;
        }
        @media #{$screen-tablet} and (orientation: landscape) {
            // padding: 0;
        }
    }


}
