@import "variables";

.login
{
    display: flex;
    flex-direction: row;
    align-items: center;

    
}




.login-elements-a
{
    font-size: $desktop-menu-font-size;
    line-height: 28px;
    color: $text-color; 
    font-weight: 500;
    transition: color .4s;
    display: flex;
    justify-content: center;
    align-items: center;

    &:link,&:visited
    {
        text-decoration: none;
    }
    &:hover,&:active
    {
      color: $text-color; 
    }
    @media #{$tablet-portrait-and-down} {
        margin-right: 0px;
        font-size: 16px;
    }  
}

.login-text {
    padding-top: 3px;
    margin-right: 10px;
    color: white;
}

.login-logo {
    display: inline;

    width: 44px !important;
    height: 85px !important;
    line-height: 45px;

    transition: display .4s;
    
    @media #{$tablet-portrait-and-down} {
        display: none;
    }
}

.login-icon .animicon-canvas
{
    width: 34px;
    height: 34px;
}
