@import "variables";

.terms-and-conditions {

  padding: 24px 30px;
  display: flex;
  align-items: center;
  // justify-content: center;
  flex-direction: column;
  text-align: left;
  background: $bp-light;  
  color: $text-color-inverted;
  min-height: 100vh;
  gap: 135px;

  @media screen and (max-width: 700px) {
    gap: 50px;
  }
  @media #{$screen-mobile} {
    padding: 24px 10px;
    align-items: start;
  }


  .content-wrapper {
    max-width: 900px;
    letter-spacing: 1px;
  }

  .title {
    font: $font-h1;
    letter-spacing: 2px;
    text-transform: uppercase;
    @media #{$screen-mobile} {
      font: $font-h1-mobile;
    }
  }

  p {
     font: $font-h3;
    letter-spacing: 1px;
    @media #{$screen-mobile} {
      font: $font-weight 16px/24px $font-primary;
    }
  }

  .header {
    // position: relative;
    // display: inline-block;
    width: 278px;
    height: 100%;

    .underscore-img {
        position: absolute;
        width: 100%;
        transform: scale(0.8);
        top: 15px;
        left: 2px;
        height: 20px;
        
        @media #{$tablet-portrait-and-down} {
          top: 12px;
          left: 1px;
          transform: scale(0.7);
        }

        @media #{$screen-mobile} {
            top: 10px;
            left: 1px;
            transform: scale(0.6);
        }
    }
    .cta-button{
      height: 38px;
      // border-radius: 4px;
      border: 2px solid $secondary-header;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;

      .header-text {
        font: $font-nav;
        letter-spacing: 0.07em;
        // border: none;
        color: $secondary-header;
        text-align: center;

        @media #{$tablet-portrait-and-down} {
            font-size: $tablet-font-size;
            line-height: $tablet-line-height;
        }

        @media #{$screen-mobile} {
            font: $font-nav-mobile;
        }
    }
    }
    
}
}
