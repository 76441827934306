@import "variables";

.header {
    position: relative;
    display: inline-block;

    .underscore-img {
        position: absolute;
        width: 100%;
        transform: scale(0.8);
        top: 39px;
        left: 2px;
        height: 20px;
        
        @media #{$tablet-portrait-and-down} {
            top: 36px;
            left: 1px;
            transform: scale(0.7);
        }

        @media #{$screen-mobile} {
            top: 33px;
            left: 1px;
            transform: scale(0.6);
        }
    }

    .header-text {
        font-size: $desktop-font-size;
        line-height: $desktop-line-height;
        border: none;

        @media #{$tablet-portrait-and-down} {
            font-size: $tablet-font-size;
            line-height: $tablet-line-height;
        }

        @media #{$screen-mobile} {
            font-size: $mobile-font-size;
            line-height: $mobile-line-height;
        }
    }
}