.cubhouse-wrapper
{
    width: 100%;
    max-width: 940px;
    // margin: auto;
    display: flex;
    flex-direction: column;
    gap: 15px;

    @media #{$screen-mobile} {
        gap: 20px;
    }
    @media #{$screen-tablet} and (orientation: landscape) {
        margin: auto;
    }

    .cubhouse-header {
        position: relative;
        // display: none;
        // padding-bottom: 50px;
        text-align: left;
        display: flex;
        flex-direction: column;
        gap: 10px;


        .cubhouse-header-text {
            // text-align: center;
            width: 100%;
            font: $font-weight-bold 20px/30px $font-semi-bold;
            letter-spacing: 2px;
            color: $primary-header;
            @media #{$screen-mobile} {
                font: $font-h1-mobile;
            }
        }
        .cubhouse-subheading {
            // text-align: center;
            width: 100%;
            font:  $font-weight 18px/27px $font-light;
            letter-spacing: 1px;
            color: $primary-header;
            @media #{$screen-mobile} {
                font: $font-weight 16px/24px $font-light;
            }
            .nowrap {
                white-space: nowrap;
            }
        }

        @media #{$tablet-portrait-and-down} {
            // display: inline-block;
            width: 100%;
            
        }
        @media #{$screen-mobile} {
            width: 100%;
            text-align: center  ;
            gap: 14px;
        }
    }

    .hero-wrapper {
        margin: 60px 0 0;
        width: 1152px;
        height: 226px;
        position: relative;
        text-align: center;
        left: -120px;

        @media #{$tablet-portrait-and-down} {
            left: 0;
            width: 100%;
            margin: 0;
            height: 250px;
        }
        @media #{$screen-tablet} and (orientation: landscape) {
            display: none;
        }

        @media #{$screen-mobile} {
            height: 219px;
        }
    }

    .hero-animate {
        position: absolute;
        bottom: 0;
        transform: translateX(-50%);
        left: 50%;
        width: 1152px;
        height: 226px;
        backface-visibility: hidden;
        perspective: 1000px;

        @media #{$desktop-and-down} and (orientation: portrait) {
            transform: initial;
            left: initial;
        }

        @media #{$screen-mobile} {
            left: initial;
            transform: initial;
            width: 1119px;
            height: 219px;
        }

    }

    .hero-image {
        display: block;
        height: 100%;

        img {
            height: 100%;
        }
    }
    .pigeons-lottie {
        width: 104px;
        position: absolute;
        top: 73.45%; //166px;
        left: 13.45%; //155px;
        margin: 0 !important;

        //@media #{$desktop-and-down} and (orientation: portrait) {
        //    top: 172px;
        //    left: 165px;
        //}
        //
        //@media #{$tablet-portrait-and-down} {
        //    top: 172px;
        //    left: 165px;
        //}

        @media #{$screen-mobile} {
            // top: -6px;
            // width: 372px;
        }
    }
    .leaf-lottie {
        width: 122px;
        position: absolute;
        //top: 89px;
        //left: 603px;
        top: 67.25%; //152px;
        left: 53.64%; //618px;
        margin: 0 !important;

        //@media #{$desktop-and-down} and (orientation: portrait) {
        //    top: 154px;
        //    left: 623px;
        //}
        //
        //@media #{$tablet-portrait-and-down} {
        //    top: 154px;
        //    left: 623px;
        //    //top: 108px;
        //    //left: 388px;
        //}

        @media #{$screen-mobile} {
            // top: -6px;
            // width: 372px;
        }
    }

}

.cubhouse-nav
{
    // margin-top: 65px;
    overflow: hidden;
    display: none;

    // @media #{$tablet-portrait-and-down}
    // {
    //     display: flex;
    //     justify-content: flex-start;
    // }
    // @media #{$screen-tablet} and (orientation: landscape) {
    //     &::-webkit-scrollbar {
    //         height: 3px;
    //     }
    // }
    // @media #{$screen-mobile} {
    //     margin-top: 0px;
    //     &::-webkit-scrollbar {
    //         height: 3px;
    //     }
    // }
    .tab {
        margin-left: 0px;

        // @media #{$screen-tablet} and (orientation: landscape) {
        //     margin-left: 2px;
        // }
        // @media #{$screen-mobile} {
        //     margin-left: 2px;
        // }
    }

    .tab-underline.img-underscore  {
        width: 47px;
        top: -25px;
        left: -2px;
        @media #{$screen-tablet} and (orientation: landscape) {
            height: 25px;
            width: 40px;
            top: -2px;
            left: 0px;
            transform: scaleX(1.2);
        }
        @media #{$screen-mobile} {
            height: 25px;
            width: 40px;
            top: -2px;
            left: 0px;
            transform: scaleX(1.2);
        }
    }
    
    .tab-button {
        margin: 0;
        font: $font-nav;
        letter-spacing: 0.07em;
        width: 48px;
        text-align: left;
        // @media #{$screen-tablet} and (orientation: landscape) {
        //     font-size: $mobile-font-size;
        // }
        @media #{$screen-mobile} {
            font: $font-nav-mobile;
        }
    }
    .tabs {
        // padding-left: 3px;
        height: 39px;
        width: 100%;
        display: flex;
        // justify-content: space-between;
        gap: 26px;
        
        overflow-y: hidden;
        overflow-x: hidden;
        scrollbar-width: auto;

        &:hover {
            overflow-x: auto;
        }
        &::-webkit-scrollbar {
            background-color: #C37F6E;
            height: 4px;
            border-radius: 10px;
            margin-left: 6px;

        }
            
        &::-webkit-scrollbar-thumb {
            background: #FFF6F0;
            border-radius: 10px;
    
            &:hover {
                background: #FFF6F0;
            }
        }
        &::-webkit-scrollbar-track {
            border-radius: 10px;
        }
        
        @media #{$screen-tablet} {
            height: 40px;
            overflow-x: auto;
            // padding-left: 5px;
            // margin-left: -4px;
        }
        @media #{$screen-mobile} {
            height: 35px;
            gap: 17px;
        }
        @media #{$screen-tablet} and (orientation: landscape) {
            height: 35px;
        }
    }
}



.carousel-block
{
    width: 100%;
    position: relative;
    // @media #{$screen-mobile} {
    //     padding: 0 25px;
    // }
    
}

.carousel-wrapper
{
    width: 100%;
    overflow: hidden;
}

.carousel-track
{
    position: relative;
    overflow: hidden;
}

.carousel-slides
{
    width: 100%;
    display: flex;
    flex-direction: row;

    > div
    {
        width: 100%;
        height: 100%;
        flex-shrink: 0;
        white-space: normal;
        user-select: none;
        -webkit-touch-callout: none;
        -webkit-tap-highlight-color: transparent;
    }
}

.cubhouse-wrapper{
    .carousel-cta
{
    display: block;
    user-select: none;
    -webkit-user-drag: none;
    -moz-user-select: none;
    -ms-user-select: none;
    position: relative;

    .preloaded
    {
        width: 100%;
        height: auto;
    }

    .info-wrap
    {
        display: block;
        position: absolute;
        top: 0%;
        left: 0%;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,.8);
        opacity: 0;
        transition: opacity .4s;
    }
    &:link, &:visited
    {
        text-decoration: none;
    }
    &:hover
    {
        .info-wrap
        {
            opacity: 1;
        }
    }

    .info
    {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 90%;
        transform: translate(-50%, -50%);
        color: #ffffff;
        font: $font-weight 14px/17px $font-primary;
        letter-spacing: 0.07em;
        // font-size: 10px;
        // line-height: 17px;

        // @media #{$tablet-portrait-and-down}
        // {
        //     font-size: 8px;
        //     line-height: 12px;
        // }

        .title,
        .subtitle
        {
            display: block;
        }

        .title
        {
            // text-transform: uppercase;
            margin-bottom: 25px;
            font-family: $font-semi-bold !important;
            // font-size: 15px;
            // line-height: 22px;
        }
    }

    ul
    {
        list-style: none;
        padding: 0;
    }
}
}

.cubhouse-left
{
    position: absolute;
    right: 100%;
    top: 50%;
    transform: translateY(-50%);
}

.cubhouse-right
{
    position: absolute;
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
}

.cubhouse-left-cta
{
    border: 0;
    padding: 0px 10px 0px 0px;
    > .cubhouse-cta-icon
    {
        background: url(/assets/svg/arrow_left.svg) no-repeat center center;
        display: block;
        width: 24px;
        height: 24px;
    }

    @media #{$tablet-portrait-and-down}
    {
        padding: 5px;
    }
}

.cubhouse-right-cta
{
    border: 0;
    padding: 0px 0px 0px 10px;
    > .cubhouse-cta-icon
    {
        display: block;
        background: url(/assets/svg/arrow_right.svg) no-repeat center center;
        width: 24px;
        height: 24px;
    }

    @media #{$tablet-portrait-and-down}
    {
        padding: 5px;
    }
}

.fl-link{
    font: $font-nav;
    letter-spacing: 0.07em;
    text-align: left;
    text-transform: uppercase;
    @media #{$screen-mobile} {
        font: $font-nav-mobile;
        text-align: center;
        // padding: 0px 25px;
    }
    @media #{$screen-small} {
        font: $font-weight-bold 12px/39px $font-bold;
    }
    a{
        color: $secondary-header;
        text-decoration: none;
        display: inline-flex;
        justify-content: flex-start;
        align-items: center;
        gap: 11px;

        @media #{$screen-mobile} {
            justify-content: center;
            gap: 8px
        }

        &:hover
        {
            transition: 400ms;
            color: $secondary-hover;
            .arrow-mobile{
                transition: 400ms;
                background: url(/assets/svg/Learn_More_mobile_hover.svg) no-repeat center center;
            }
            .arrow-desktop{
                transition: 400ms;
                background: url(/assets/svg/Learn_More_desktop_hover.svg) no-repeat center center;
            }
        }
        .learn-more-arrow{
            width: 12px;
            height: 12px;
            display: block;
        }
        .arrow-mobile{
            background: url(/assets/svg/Learn_More_mobile_default.svg) no-repeat center center;
        }
        .arrow-desktop{
            background: url(/assets/svg/Learn_More_desktop_default.svg) no-repeat center center;
            margin-top: 1px;
        }
    }
}
@media #{$tablet-portrait-and-down}
{
    .credits li
    {
        display: inline-block;
        
        &::after
        {
            content: ',';
            display: inline-block;
        }

        &:last-child::after
        {
            content: none;
        }
    }
}

