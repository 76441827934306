@import "variables";

.icons {
    // display: grid;
    // grid-template-columns: 0.5fr 0.5fr;
    // grid-gap: 50px;
    // gap: 11px 30px;
    // margin: auto;
    // margin-top: 65px;
    width: -moz-fit-content;
    width: fit-content;

    // @media #{$tablet-portrait-and-down} {
    //     // margin-top: 39px;
    //     gap: 11px 20px;
    //     margin-bottom: 28px;
    // }
    // @media #{$screen-mobile} {
    //     // margin-top: 28px;
    //     // padding-right: 10px;
    //     // padding-top: 10px;
    // }
    // @media #{$screen-tablet} and (orientation: landscape) {
    //     // margin-top: 31px;
    //     gap: 20px 0px;
    //     margin-bottom: 19px;
    // }
}
.dates {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 28px;
    @media #{$screen-mobile} {
        gap: 22px;
    }
}
.entries-closed-image {
    position: absolute;
    width: 260px;
    margin: 48px 8px;

    @media #{$tablet-portrait-and-down} {
        margin: 36px 30px;
        width: 210px;
    }
    @media #{$screen-tablet} and (orientation: landscape) {
        margin: 56px 30px;
        width: 210px;
    }
}
.icon-group {
    display: flex;
    flex-direction: row;
    // gap: 39px;
    // justify-content: center;
    align-items: flex-start;
    white-space: nowrap;
    // margin-bottom: 50px;
    
    // &:nth-of-type(2) {
    //     .icon-logo {
    //         left: 2px;
    //     }
    // }
    // @media #{$tablet-portrait-and-down} { 
    //     flex-direction: column;
    // }
    @media #{$screen-mobile} { 
        // gap: 31px;
        // margin-bottom: 30px;
    }
    // @media #{$screen-tablet} and (orientation: landscape) {
    //     &:first-of-type {
    //         margin-left: -7%;
    //     }
    //     &:last-of-type {
    //         margin-left: -1%;
    //     }
    // }

}

.icon-logo {
    width: 25px !important;
    height: 25px !important;

    @media #{$screen-mobile} {
        font-size: unset !important;
    }
    // position: relative;
    // left: 10px;

    // @media #{$screen-tablet} and (orientation: landscape) {
    //     width: 51px !important;
    //     height: 46px !important;
    // }
    // @media #{$tablet-portrait-and-down} {
        // width: 51px !important;
        // height: 46px !important;
    // }
    svg {
        width: 60px;
        height: 60px;
    }
}

.icon-logo-padded {
    margin-left: 4px;
    width: 60px !important;
    height: 59px !important;
    position: relative;
    top: 4px;
    &:nth-of-type(2) {
        margin-left: -3px;

        @media #{$tablet-portrait-and-down} {
            margin-left: 7px;
        }
    }
    @media #{$tablet-portrait-and-down} {
        margin-left: 13px;
        width: 46px !important;
        height: 46px !important;
    }
    @media #{$screen-tablet} and (orientation: landscape) {
        width: 43px !important;
        height: 46px !important;;
    }
}

.icon-text-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: $desktop-font-size-high;
}

.icon-date {
    color: $primary-header;
    font: $font-weight-bold 20px/30px $font-semi-bold;
    letter-spacing: 2px;
    @media #{$screen-mobile} {
        font: $font-h2-mobile;
    }

    @media #{$screen-tablet} and (orientation: landscape) {
        // margin-top: -2px;
        // padding-left: 5px;
        // line-height: $mobile-line-height;
    }
    @media #{$tablet-portrait-and-down} {
        // margin-top: -2px;
        // padding-left: 5px;
        // line-height: $mobile-line-height;
    }
}

.icon-description {
    color: $primary-body;
    font: $font-h3;
    letter-spacing: 1px;
    @media #{$screen-mobile} {
        font: $font-h3-mobile;
    }
    

    @media #{$screen-tablet} and (orientation: landscape) {
        // padding-left: 5px;
        // min-width: 100px;
        // line-height: $mobile-line-height;
    }
    @media #{$tablet-portrait-and-down} {
        // padding-left: 5px;
        // min-width: 100px;
        // line-height: $mobile-line-height;
    }
}

