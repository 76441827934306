.cookie-banner
{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    min-height: 50px;
    z-index: 800;
    background: white;
    font-weight: 300;
    transition: transform .5s;

    &.collapsed
    {
        transform: translateY(100%);
    }
    &.immediate
    {
        transition: none;
    }
}

.cookie-banner-content
{
    padding: 20px 30px;
    max-width: 1000px;
    margin: 0 auto;
    display: flex;
    align-items: center;

    a
    {
        color: #121212;
        &:link,&:visited
        {
        }
        &:hover,&:active
        {

        }
    }
}

.cookie-banner-copy
{
    margin-right: 20px;
    font-size: 10px;
    text-align: left;
    line-height: 19px;
    color: #122;
}

.cookie-banner-cta-button {
  border-color: #122;
}
