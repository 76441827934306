@import "variables";

.tabs
{
    display: flex;
    flex-direction: row;

    @media #{$tablet-portrait-and-down}
    {
        // padding-left: 30px;
        display: inline-flex;
    }
}

.tab
{
    // margin-right: 20px;
    // margin-left: 1px;
    position: relative;
    // opacity: 1;
    transition: opacity .4s;
    color: $text-color;
    // &:last-of-type {
    //     margin-right: 0px;
    // }

    // &.tab-active,
    // &:hover
    // {
    //     opacity: 1;
    // }

    // @media #{$screen-desktop} {
    //     margin-right: 30.5px;
    //     margin-left: 6px;
    // }
}

.tab-underline
{
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    height: 1px;
    background-color: $text-color;
    opacity: 0;
    transition: opacity .4s;

    .tab.tab-active &
    {
        opacity: 1;
    }
}

.img-underscore {
    position: absolute;
    height: 80px;
    width: 151px;
    z-index: -1;
    background: transparent;

    // background-image: url('./../../assets//2023/alumni/Alumni_circle.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
}

.tab-button
{
    border: 0;
    padding: 0;
    // opacity: 0.4;
    font-size: $desktop-font-size;
    line-height: $desktop-line-height-high;
    color: $secondary-header;
    .tab.tab-active &
    {
        color: $primary-header;
        // opacity: 1;
    }
    &:hover
    {
        color: $secondary-hover;
        // opacity: 1;
    }
    // @media #{$tablet-portrait-and-down} {
    //     font-size: $tablet-font-size;
    //     line-height: $tablet-line-height;
    // }

    @media #{$screen-mobile} {
        font-size: $mobile-font-size;
        line-height: $mobile-line-height;
    }
    @media #{$screen-tablet} and (orientation: landscape) {
        font-size: $mobile-font-size;
        line-height: $mobile-line-height;
    }
    
}


