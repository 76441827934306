@import "variables";

.bulletpoints {
  display: flex;
  flex-direction: column;
  position: relative;
  // border: 1px solid yellow;
  @media #{$desktop-and-down}
  {
      display: none;
  }

  &:after {
    position: absolute;
    top: 0;
    content: "";
    left: 50%;
    height: 100%;
    transform: translateX(-50%);
    width: 0px;
    background: $text-color;
    z-index: -1;

    @media #{$screen-tablet} {
      width: 1px;
    }
  }

  .bulletpoint-indicator {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 0px;
    background: $text-color;
    height: 35px;
    z-index: 0;
    transition: 200ms;

    @media #{$screen-tablet} {
      width: 1px;
      height: 23px;
    }
  }

  .bulletpoint-wrapper {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 20px;
    // padding: 7px 10px;
    width: 220px;
    position: relative;
  }
  .bulletpoint-wrapper:hover {
    .bulletpoint {
        transition: 700ms;
    }
    .bullet-label
    {
        color: $secondary-hover;
    }
    .bullet-image {
      // display: block;
    }
  }
  .bulletpoint-wrapper.active-bullet {
      .bulletpoint {
          // background-color: $secondary-hover;
          transition: 700ms;
      }
      .bullet-label
      {
          color: $primary-header;
      }
      .bullet-image {
        display: block;
      }
  }

  .bulletpoint {
    visibility: hidden;
    width: 15px;
    height: 25px;
    display: flex;
    justify-content: center;

    @media #{$screen-tablet} {
      width: 3px;
      height: 3px;
    }
  }
}

.bullet-label
{
    // position: absolute;
    // opacity: 1;
    text-align: left;
    left: 45px;
    // top: 50%;
    width: max-content;
    // transform: translateY(-50%);
    color: $secondary-header;
    transition: all .4s;
    font: $font-weight-bold 16px/39px $font-semi-bold;
    letter-spacing: 0.07em;
    // font-style: normal;
    // font-size: $desktop-font-size;
    // line-height: 40px;
    // letter-spacing: 1px;

}


.bullet-image {
  width: 100%;
  display: none;
  // position: absolute;
  // height: 39px;
  // left: -10px;
  // top: -2px;
  // z-index: -10;
}

.bullet-image-home {
  width: 66px;
  height: 22px;
  left: -9px;
  top: -3px;
  transform: scaleX(1.3);
}

.bullet-image-brief {
  width: 58px;
}

.bullet-image-milestones {
  width: 101px;
  left: -12px;
}

.bullet-image-alumni {
  width: 80px;
  left: -11px;
  top: -3px;
}

.bullet-image-faq {
  width: 51px;
  left: -8px;
}