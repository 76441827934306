@import "variables";
$font-loc: "/assets/fonts/";

@mixin get-font-all($name, $weight) {
  font-family: #{$name}-#{$weight};
  src: /* Safari, Android, iOS */ url("#{$font-loc}#{$name}/#{$name}-#{$weight}.woff")
  format("woff"),
    /* Default */ url("#{$font-loc}#{$name}/#{$name}-#{$weight}.eot?#iefix")
  format("embedded-opentype"),
    /* IE6-IE8 */ url("#{$font-loc}#{$name}/#{$name}-#{$weight}.otf")
  format("opentype"),
    /* Legacy iOS */ url("#{$font-loc}#{$name}/#{$name}-#{$weight}.ttf")
  format("truetype"),
    /* Modern Browsers */ url("#{$font-loc}#{$name}/#{$name}-#{$weight}.woff2")
  format("woff2"); /* Modern Browsers */
}

@font-face {
  @include get-font-all('Volvo-Novum', 'Regular');
}

@font-face {
  @include get-font-all('Volvo-Broad-CN', 'Regular');
}

@font-face {
  @include get-font-all('Volvo-Novum', 'Light');
}
@font-face {
  @include get-font-all('Volvo-Novum', 'Medium');
}

@font-face {
  @include get-font-all('EverettMono', 'Regular');
}
@font-face {
  @include get-font-all('Barlow', 'Light');
}
@font-face {
  @include get-font-all('Barlow', 'Regular');
}
@font-face {
  @include get-font-all('Barlow', 'Medium');
}
@font-face {
  @include get-font-all('Barlow', 'SemiBold');
}
@font-face {
  @include get-font-all('Barlow', 'Bold');
}