@import "variables";

.faq
{
    width: 100%;
    // width: 828px;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: flex-start;
    // margin: auto;
    gap: 30px;

    // margin: auto;
    // margin-left: 10%;
    // padding-bottom: 132px;
    // height: 591px;
    @media #{$laptop-and-down} {
        // width: 700px;
    }
    @media #{$desktop-and-down} {
        // width: 540px;
        .faq-copy{
            // gap: 20px;
        }
    }
    @media #{$screen-mobile} {
        flex-direction: column;
        // width: 90%;
        // gap: 54px;
    
        .faq-panels{
            width: 389px;
            // height: 376px;
            height: 400px;
            @media #{$screen-small} {
                width: 100%;
            }
        }
        
    }
    
    @media #{$tablet-portrait-and-down} {
        // height: 576px;
        // margin-left: 0;
        // padding-bottom: 126px;
        // gap: 20px;
    }
    @media #{$screen-mobile} {
        gap: 13px;
        // height: 519px;
        // padding-bottom: 62px;
    }
    // @media #{$screen-mid-height} {
    //     gap: 10px;
    // }
    @media #{$screen-tablet} and (orientation: landscape) {
        // padding-bottom: 0;
        // height: 331px;
        // width: 80vw;
    }
}
.nowrap {
    white-space: nowrap;
}

.faq-header {
    position: relative;
    display: none;
    text-align: left;
    
    @media #{$screen-mobile} {
        // margin-bottom: 38px;
    }
    .faq-underscore-img {
        position: absolute;
        z-index: -1;
        width: 114px;
        transform: scaleX(0.6);
        
        @media #{$screen-tablet} and (orientation: landscape) {
            transform: scaleX(0.45) scaleY(0.5);
            top: -3px;
            left: -39px;
        }
        @media #{$screen-mobile} {
            transform: scaleX(0.45) scaleY(0.5);
            top: -3px;
            left: -39px;
        }
    }

    .faq-header-text {
        text-align: center;
        width: 100%;
        font: $font-h1;
        letter-spacing: 2px;
        @media #{$screen-mobile} {
            text-align: center;
            font: $font-h1-mobile;
        }
    }

    @media #{$tablet-portrait-and-down} {
        
        // width: 100%;
        // margin-left: 4px;
    }
    @media #{$screen-tablet} and (orientation: landscape) {
        // width: 100%;
        // margin-left: 1px;
    }
    @media #{$screen-mobile} {
        display: inline-block;
        width: 100%;
        // margin-left: 1px;
    }
}

.faq-nav
{
    // margin-left: -7px;
    // overflow: hidden;
    // width: 329px;
    text-align: left;
    .tabs {
        // padding-bottom: 90px;
        // padding-left: 3px;
        display: flex;
        flex-direction: row;
        // height: 287px;
        justify-content: flex-start;
        gap: 119px;
    }

    .tab {
        // margin-right: 120px;
    }

    @media #{$tablet-portrait-and-down}
    {
        // margin-bottom: 5px;
        // margin-left: 1px;
        

        .tabs {
            // padding: 30px 0;
            display: flex;
            // justify-content: space-between;
        }

        .tab {
            // margin-right: 60px;
            // margin-left: 1px;
        }
    }
    @media #{$screen-tablet} and (orientation: landscape) {
        .tabs {
            // justify-content: space-between;
            // padding: 20px 0;
        }

        .tab {
            // margin-right: 1px;
            // margin-left: 0px;
        }
    }
    @media (max-width: 750px) {
        width: 100%;
        .tabs {
            justify-content: space-between;
            flex-direction: row;
            height: unset;
            gap: unset;
        }
    }
    @media #{$screen-mobile} {
        width: 100%;
        .tabs {
            justify-content: space-between;
            flex-direction: row;
            height: unset;
            gap: unset;
            // padding: 20px 0;
        }

        .tab {
            // margin-right: 1px;
            // margin-left: 0px;
        }
    }

    .tab-button {
        // margin:0;
        // font-size: $desktop-font-size;
        // letter-spacing: 1px;
       font: $font-weight-bold 16px/39px $font-semi-bold;
       letter-spacing: 0.07em;

        // @media #{$screen-tablet} and (orientation: landscape) {
        //     font-size: $mobile-font-size;
        //     line-height: $mobile-line-height;
        // }
        @media #{$screen-mobile} {
            font: $font-nav-mobile;
        }
    }

    .tab-underline.img-underscore  {
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;

        top: 0px;
        left: -20px;
        transform: scaleX(.9);
    }

    .tab-underline.img-underscore[data-index="0"]  {
        
        // background-image: url('./../../assets/2023/faq/FAQ_underscore.svg');
        top: -8px;
        left: -49px;
        transform: scale(.5, 0.7);   
          
        @media #{$screen-tablet} and (orientation: landscape) {
            top: -17px;
            left: -55px;
            transform: scale(0.4, 0.4);
        }
        @media #{$screen-mobile} {
            top: -17px;
            left: -55px;
            transform: scale(0.4, 0.4);
        }
    }
    .tab-underline.img-underscore[data-index="1"]  {
        // background-image: url('./../../assets/2023/faq/FAQ_underscore.svg');
        top: -8px;
        left: -40px;
        transform: scale(.6, 0.7); 

        @media #{$screen-tablet} and (orientation: landscape) {
            top: -17px;
            left: -43px;
            transform: scale(0.5, 0.4);
        }
        @media #{$screen-mobile} {
            top: -17px;
            left: -43px;
            transform: scale(0.5, 0.4);
        }
    }
    .tab-underline.img-underscore[data-index="2"]  {
        // background-image: url('./../../assets/2023/faq/FAQ_underscore.svg');
        top: -8px;
        left: -49px;
        transform: scale(.45, 0.7); 

        @media #{$screen-tablet} and (orientation: landscape) {
            top: -17px;
            left: -53px;
            transform: scale(0.4, 0.4);
        }
        @media #{$screen-mobile} {
            top: -17px;
            left: -53px;
            transform: scale(0.4, 0.4);
        }


    }
    .tab-underline.img-underscore[data-index="3"]  {
        // background-image: url('./../../assets/2023/faq/FAQ_underscore.svg');
        top: -8px;
        left: -40px;
        transform: scale(.55, 0.7); 

        @media #{$screen-tablet} and (orientation: landscape) {
            top: -17px;
            left: -46px;
            transform: scale(0.4, 0.4);
        }
        @media #{$screen-mobile} {
            top: -17px;
            left: -46px;
            transform: scale(0.4, 0.4);
        }
    }
}


.faq-copy {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap:20px;
    height: 100%;

    @media #{$tablet-portrait-and-down} {
        // max-width: 528px;
    }
    @media #{$screen-mobile} {
        gap:20px;
    }
    @media #{$screen-mobile} and (orientation: landscape)  {
        height: 55vh;
        overflow-y: auto;
    }
}
.faq-panels{
    // width: 445px;
    height:330px;
    // height: 565px;
}
.faq-panel
{
//    position: absolute;
   opacity: 0;
   text-align: left;
   transition: opacity .4s;
   pointer-events: none;
   will-change: opacity;
   height: 100%;

   &.active
   {
       opacity: 1;
       pointer-events: auto;
   }
}

.faq-panel-paragraph
{
    // margin-bottom: 32px;

    @media #{$tablet-portrait-and-down}
    {
        // margin-bottom: 16px;
    }
}

.faq-panel-paragraph a
{
    &:link,&:visited
    {
        color: $text-color;
        text-decoration: none;
    }
}
.faq-panel-title{
    // color: $primary-header;
    color: #FFF6F0;
    font: $font-h2;
    letter-spacing: 2px;
    @media #{$screen-mobile} {
        font: $font-h2-mobile;
    }
}
.faq-panel-desc{
    color: $primary-body;
    font: $font-ques;
    letter-spacing: 1px;
    @media #{$screen-mobile} {
        font: $font-ques-mobile;
    }
}
// .faq-panel-title,
// .faq-panel-desc
// {
//     font-size: $desktop-font-size;
//     line-height: 32px;
//     letter-spacing: 1px;
//     margin: 0;
//     color: $text-color;

//     @media #{$screen-tablet} and (orientation: landscape) {
//         font-size: $mobile-font-size;
//     }

//     @media #{$screen-mobile} {
//         font-size: $mobile-font-size;
//     }
// }
// .faq-panel-desc {
//     font-size: $desktop-font-size;;
//     line-height: 22px;
//     font-weight: 300;
    
//     @media #{$screen-tablet} and (orientation: landscape) {
//         font-size: $mobile-font-size;
//         line-height: 16px;
//     }
//     @media #{$screen-mobile} {
//         font-size: $mobile-font-size;
//         line-height: $mobile-line-height;
//     }
// }

// .faq-panel-title
// {
//     color:  $text-color;
//     font-weight: 600;
// }

.faq-panel-title-link
{
    color: $text-color;
}
