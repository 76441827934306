@import "variables";

.privacy-policy
{
  padding: 24px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  background: $bp-light;
  color: $text-color-inverted;
  letter-spacing: 1px;

  .privacy-policy-content-wrapper {
    max-width: 900px;
  }
  .privacy-title-h2,
  .privacy-title {
    font-size: 24px;
    line-height: 51px;
    font-weight: 600;
    margin: 0 0 30px 0;

    @media #{$screen-mobile} {
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 11px;
    }
  }
  .privacy-title-h2
  {
    font-size: 20px;
    line-height: 48px;

    @media #{$screen-mobile} {
      font-size: 18px;
      line-height: 24px;
    }
  }

  p {
    line-height: 24px;
    font-size: 14px;
    font-weight: 300;

    @media #{$screen-mobile} {
      font-size: 13px;
      line-height: 22px;
      font-weight: 300;
    }
  }

  ul, ol
  {
      padding-left: 18px;
  }
  li
  {
    line-height: 24px;
    font-size: 14px;
    font-weight: 300;
  }

  button {
    margin: 24px 0;
    color: $text-color-inverted;
  }

  .content
  {
      h1, h2, h3, h5
      {
          font-weight: 300;
      }

      a
      {
        color: $text-color-inverted;
          &:link, &:visited
          {
          }
          &:hover,&:active
          {

          }
      }
  }
}
