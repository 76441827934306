.fl-icon {
  position: relative;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 32px;
  overflow: visible;

  svg {
    width: 100%;
    height: 100%;
  }
}
