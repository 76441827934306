@import "variables";

.fake-scroller
{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: calc(100%);
  height: calc(100%);
  min-height: calc(100%);
  z-index: 500;
  scrollbar-width: none;
}

.content {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  height: 100%;
  &.pages{
    // background: $gradient-complete;
    background: url('../../assets/2025/bg/Background.webp') center/cover no-repeat;
  }
  &.home{
    transition: 500ms;
    // background: url('../../assets/2024/bg/1.webp') center/cover no-repeat;

  }
  &.dates{
    transition: 500ms;
  //   background: url('../../assets/2024/bg/dates.webp') center/cover no-repeat;
  }
  &.brief{
    transition: 500ms;
    // background: url('../../assets/2024/bg/2.webp') center/cover no-repeat;
  }
  &.soty{
    transition: 500ms;
    // background: url('../../assets/2024/bg/3.webp') center/cover no-repeat;
  }
  // &.winners{
  //   transition: 500ms;
  //   // background: url('../../assets/2024/bg/4.webp') center/cover no-repeat;
  // }
  // &.awards{
  //   transition: 500ms;
  //   // background: url('../../assets/2024/bg/5.webp') center/cover no-repeat;
  // }
  &.alumni{
    transition: 500ms;
    // background: url('../../assets/2024/bg/6.webp') center/cover no-repeat;
  }
  &.faq{
    transition: 500ms;
    // background: url('../../assets/2024/bg/6.webp') center/cover no-repeat;
  } 
}