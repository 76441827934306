@import "variables";

html {
  height: 100%;
  position: relative;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  color: $text-color;
  background: $bg-main;

  #root {
    min-height: 100%;
    display: flex;
    flex-direction: column;
  }
}

*:focus
{
    outline: -webkit-focus-ring-color auto 0px;
}

*
{
    box-sizing: border-box;
}